import * as React from "react"
import {
  DropdownMenu,
  DropdownMenuPopover,
  DropdownMenuButton,
  DropdownMenuItemsLowLevel,
  DropdownMenuLink,
  ThemeCss,
  Badge,
  Theme,
  Link,
} from "gatsby-interface"
import { useData, position, DataItem } from "./News.helpers"
import { FiBookOpen } from "react-icons/fi"
import { Interpolation, keyframes } from "@emotion/core"

function Item({ item }: { item: DataItem }) {
  return item.message ? (
    <div
      key={item.to}
      css={theme => [itemCss(theme, item.isNew), staticItemCss(theme)]}
    >
      {item.isNew && <Badge tone="SUCCESS">New</Badge>} <p>{item.title}</p>
      <p css={messageCss}>
        {item.message}{" "}
        {item.to && (
          <Link href={item.to}>{item.linkText ? item.linkText : `More`}</Link>
        )}
      </p>
    </div>
  ) : (
    <DropdownMenuLink
      key={item.to}
      href={item.to}
      target="_blank"
      css={theme => [itemCss(theme, item.isNew), linkItemCss(theme)]}
    >
      {item.isNew && <Badge tone="SUCCESS">New</Badge>} <p>{item.title}</p>
    </DropdownMenuLink>
  )
}

export function News() {
  const data = useData()

  return (
    <DropdownMenu id="user-menu">
      <DropdownMenuButton
        id="user-menu-button"
        css={(theme: Theme) => btnCss(theme, data.hasNewItems)}
      >
        <FiBookOpen />
      </DropdownMenuButton>

      <DropdownMenuPopover position={position} width="300px">
        <DropdownMenuItemsLowLevel
          size="AUTO"
          css={{
            maxHeight: `none`,
          }}
        >
          {data.announcements.length > 0 && (
            <>
              <div css={headerCss}>
                <small>Recently on Gatsby Cloud</small>
              </div>
              {data.announcements.map(item => (
                <Item key={item.title} item={item} />
              ))}
            </>
          )}

          {data.blogPosts.length > 0 && (
            <>
              <div css={headerCss}>
                <small>Recently on the Gatsby blog</small>
              </div>
              {data.blogPosts.map(item => (
                <Item key={item.title} item={item} />
              ))}
            </>
          )}

          {data.releaseNotes.length > 0 && (
            <>
              <div css={headerCss}>
                <small>Recent release notes</small>
              </div>
              {data.releaseNotes.map(item => (
                <Item key={item.title} item={item} />
              ))}
            </>
          )}
        </DropdownMenuItemsLowLevel>
      </DropdownMenuPopover>
    </DropdownMenu>
  )
}

/* styles */

const btnEntry = keyframes`
  to {
    transform: scale(1);
  }
`

const afterEntry = keyframes`
  80% {
    transform: scale(1.4);
  }
  100% {
    transform: scale(1);
  }
`

const btnCss = (theme: Theme, hasNewItems: boolean): Interpolation => ({
  animation: `${btnEntry} .5s 2s forwards`,
  transform: `scale(0)`,
  display: `flex`,
  alignItems: `center`,
  background: `none`,
  border: 0,
  fontSize: theme.fontSizes[5],
  position: `relative`,
  cursor: `pointer`,

  svg: {
    color: theme.colors.grey[50],
  },

  "&:after": {
    transform: `scale(0)`,
    animation: `${afterEntry} .5s 3s forwards`,
    content: hasNewItems ? `""` : ``,
    width: `14px`,
    height: `14px`,
    borderRadius: theme.radii[5],
    display: `block`,
    position: `absolute`,
    background: theme.colors.green[50],
    bottom: `.5rem`,
    right: `.03rem`,
    border: `2px solid white`,
  },
})

const headerCss: ThemeCss = theme => ({
  color: theme.colors.grey[60],
  display: `flex`,
  flexDirection: `column`,
  fontSize: theme.fontSizes[1],
  padding: `${theme.space[4]} ${theme.space[5]}`,
  position: `relative`,
})

const itemCss = (theme: Theme, isNew: boolean): Interpolation => ({
  padding: theme.space[3],
  paddingLeft: isNew ? theme.space[13] : theme.space[6],
  paddingRight: theme.space[6],
  position: `relative`,
  maxWidth: `25rem`,
  fontSize: theme.fontSizes[1],

  "& > span": {
    position: `absolute`,
    transform: `translate(-115%, -5%)`,
  },

  "& > p": {
    marginBottom: 0,
  },
})

const linkItemCss: ThemeCss = _theme => ({})

const staticItemCss: ThemeCss = _theme => ({})

const messageCss: ThemeCss = theme => ({
  fontSize: theme.fontSizes[0],
  marginTop: theme.space[3],
  color: theme.colors.grey[50],
})
